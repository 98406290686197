<template>
  <div class="animated fadeIn">
    <div id="Dashboard">Dashboard</div>
  </div>
</template>

<script>
export default {
  name: "dashboard"
};
</script>
